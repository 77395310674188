// Here you can add other styles

.clearfix {
    overflow: auto;
}
.float-right{
    float: right;
}
.inline-alert{
    color: red;
    font-size: 0.8em;
}
.marker{
    position: absolute;
    top: 50%;
    left: 50%;    
    border-radius: 50% 50% 50% 0;
    border: 4px solid red;
    transform: rotate(-45deg) scale(3) translate(50%,-50%);
}
.small{
    transform: scale(0.8);
    margin-left: 0px;
    margin-right: 0px
}
.card{
    box-shadow: 4px 4px 10px gray;
    background-color: white;
    &:hover{
        background-color: #f4f4ef;
    }
}

.product-photo{
    max-height: 200px;
    max-width: 100%;
    object-fit: contain;
}
.package{
    box-shadow: none !important;
    border-style: none;
    border-radius: 10px;
    height: 500px;
    margin-bottom: 50px;
    text-align: center;
    transition: transform 1s cubic-bezier(.11,-1.50,.11,1.50); 
}
div.custom-container {
    box-shadow: 4px 4px 10px gray;
    background-color: white;
    border-radius: 5px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: visible;
    &:hover{
        background-color: #f4f4ef;
    }}

svg.custom .node circle {
    fill: black;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom .node text {
    font-size: 16px;
    background-color: white;
    fill: gray;
    stroke: black;
    transform: translate(-10px, -20px );
    // text-shadow: 0 1px 4px black;
}

svg.custom .node {
    cursor: pointer;
}

svg.custom path.link {
    fill: none;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

.raw-node {
	fill: green;
    stroke: green;
    font-size: 12px;
    overflow: visible;
}

.semi-product-node {
    fill: red;
    stroke: red;
    cursor: pointer;
}

.text-field{
    overflow: visible;
}

